<template>
  <div>
    <b-overlay :show="show">
      <b-card class="p-1">
        <div
          class="mx-2 mb-2"
        >
          <b-row>
            <b-col
              style="display: flex; justify-content: start"
              cols="11"
            >
              <b-button
                variant="gradient-primary"
                :to="{ path: 'administrador-control-gabinetes-show', query: { action: 'Create' }}"
              >
                Agregar Nuevo Centro de Mando P&M
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-col>
            <b-col cols="1">
              <reporte-general
                :rows="rowsReporte"
                :columns="columnsReporte"
                :titulo-principal="tituloPrincipal"
                :titulo-secundario="tituloSecundario"
                :subtitulo="subtitulo"
                :titulo-descarga="tituloDescarga"
              />
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col cols="11">
              <div>
                <b-form-group>
                  <label>Buscar: </label>
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Buscar"
                    type="text"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col
              cols="1"
            >
              <br>
              <table>
                <tr>
                  <td style="padding-top: 4px; padding-right: 15px;">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover.v-primary
                      class="btn-icon"
                      title="Buscar"
                      variant="gradient-primary"
                      @click="searchData()"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <div>
                <vue-good-table
                  :columns="columns"
                  :rows="rows"
                  style-class="vgt-table"
                  :pagination-options="{
                    enabled: true,
                    perPage: pageLength
                  }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'poste'"
                    >
                      <b-link
                        class="font-weight-bold d-block text-nowrap"
                        :to="{ path: 'administrador-control-gabinetes-show', query: {id: props.row.id, action: 'Edit' } }"
                      >
                        {{ props.row.poste }}
                      </b-link>
                    </span>
                    <span
                      v-else-if="props.column.field === 'estado'"
                    >
                      <b-badge variant="light-success">
                        ACTIVO
                      </b-badge>
                    </span>
                    <span
                      v-else-if="props.column.field === 'acciones'"
                    >
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item
                          :to="{ path: 'administrador-control-gabinetes-show', query: {id: props.row.id, action: 'Edit' } }"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            class="mr-50"
                          />
                          <span>Editar</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </template>
                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                          Mostrar
                        </span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['10', '15', '20']"
                          class="mx-1"
                          @input="value => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> de {{ props.total }} registros </span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="value => props.pageChanged({ currentPage: value })"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
  VBTooltip,
  BLink,
  BDropdown,
  BDropdownItem,
  BBadge, BOverlay, BPagination, BFormSelect,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import ReporteGeneral from '@/components/reportes/reporteGeneral.vue'
import {
  getColumns, getRows,
  subtitulo, tituloDescarga,
  tituloPrincipal,
  tituloSecundario,
} from '@/utils/reportes/administracion/admin-gabinetes'
import { findAdminGabinete } from '@/utils/administrador/gabinetes'

export default {
  components: {
    BBadge,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    ReporteGeneral,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    BLink,
    BOverlay,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      show: false,
      pageLength: 15,
      items: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
      columnsReporte: [],
      rowsReporte: [],
      searchQuery: '',
      showOverlay: false,
      columns: [
        {
          label: 'Poste',
          field: 'poste',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Dirección',
          field: 'direccion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Acciones',
          field: 'acciones',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      rows: [],
    }
  },
  computed: {
    tituloPrincipal() {
      return tituloPrincipal
    },
    tituloSecundario() {
      return tituloSecundario
    },
    subtitulo() {
      return subtitulo
    },
    tituloDescarga() {
      return tituloDescarga
    },
  },
  async created() {
    this.rows = []
    await this.loadReport()
    this.show = false
  },
  methods: {
    async loadReport() {
      this.show = true
      // eslint-disable-next-line no-restricted-globals
      const filter = {
        where: {
          and: [
            { companyId: this.usuario.idCompany },
            {
              or: [
                { poste: { regexp: this.searchQuery } },
                { direccion: { regexp: this.searchQuery } },
              ],
            },
          ],
        },
        order: ['fechaCreacion DESC'],
      }
      const data = await findAdminGabinete(filter)
      this.rowsReporte = getRows([...data])
      // eslint-disable-next-line no-restricted-syntax
      this.show = false
      this.rows = data
      this.columnsReporte = getColumns()
      this.show = false
    },
    async searchData() {
      await this.loadReport()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
